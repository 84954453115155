import React, { useState, useEffect } from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import axios from "axios"
import CTABanner from "../components/Common/CTABanner"
import { Link } from "gatsby"
import callIcon from "../assets/images/call-icon.svg"
import messageIcon from "../assets/images/message-icon.svg"
import linkedinIcon from "../assets/images/linkedin-icon.svg"
import backIcon from "../assets/images/left-arrow.svg"
import SEO from "../components/App/SEO"
import user from "../assets/images/user.svg"
import { navigate } from "gatsby"

// import Testimonials from "../components/Brokers/Testimonials"

const BrokerDetails = () => {
  const [broker, setBroker] = useState({})
  useEffect(() => {
    const brokerId = new URL(window.location.href).searchParams.get("broker")
    // if (brokerId) { CHEcj
    axios
      .get(`${process.env.GATSBY_BACKEND_API_URL}/brokers/${brokerId}`)
      .then(results => {
        setBroker(results.data)
      })
      .catch(err => {})
    // }
  }, [])
  return (
    <Layout>
      <SEO title="Broker | Oxygen - Home Loans Made Simple" />
      <Navbar />
      <div className="container">
        <div className="back-navigation">
          <Link to="/brokers">
            <button className="btn-back">
              <img src={backIcon} alt="back icon" />
              Back to Search
            </button>
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-4 col-12 mb-3">
            <div className="single-broker-box single-broker-box__broker-detail">
              <div className="image">
                <img
                  src={broker.brokerImage ? broker.brokerImage : user}
                  alt="event"
                />
              </div>

              <div className="content">
                <h3>{broker.firstLastName}</h3>
                <span className="location">{broker.specialty}</span>
                <div className="actions">
                  <Link
                    to={`/book-a-call?broker=${broker.id}`}
                    className="broker-action message"
                  >
                    <img src={callIcon} alt="Call Broker" />
                    <span>Schedule a Call</span>
                  </Link>
                  <Link
                    to={`/contact?broker=${broker.id}`}
                    className="broker-action message"
                  >
                    <img src={messageIcon} alt="Message Broker" />
                    <span>Send a Message</span>
                  </Link>
                  {broker.linkedIn ? (
                    <Link
                      to={broker.linkedIn}
                      className="broker-action message"
                      target="_blank"
                    >
                      <img src={linkedinIcon} alt="Message Broker" />
                      <span>{broker.firstName}'s LinkedIn</span>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12">
            <div className="broker-about">
              <h3>About {broker.firstLastName}</h3>
              <p>{broker.bio}</p>
            </div>
          </div>
        </div>
      </div>

      {/* testimonails */}
      {/* <div className="testimonials">
        <Testimonials name={broker.firstName} />
      </div> */}
      <div className="mt-5">
        <CTABanner />
      </div>
      <Footer />
    </Layout>
  )
}

export default BrokerDetails
